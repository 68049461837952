
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import EditAssignedServicesOfCompanyModal from "@/components/EditAssignedServicesOfCompanyModal.vue";
import ManageRequestedServicesOfCompanyModal from "@/components/ManageRequestedServicesOfCompanyModal.vue";
import * as Api from "@/types/graphql";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";
import gql from "graphql-tag";
import { CompaniesListTypes } from "@/store/modules/data";
import { extractServicsFromCompanyServices } from "@/utils/services";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    SelectedServices,
    EditAssignedServicesOfCompanyModal,
    ManageRequestedServicesOfCompanyModal,
  },
  watch: {
    options: {
      handler() {
        this.fetchCompanies(false);
      },
      deep: true,
    },
  },
})
export default class CompaniesServicesTable extends Vue {
  @State((state: StateStore) => state.data.companies) companies;
  @State((state: StateStore) => state.data.companiesLoading) companiesLoading;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedCompanies;
  @Mutation setSelectedServices;
  @Action getCompanies;

  companiesShown: Api.Company[] = [];
  search = "";

  options = {
    itemsPerPage: 10,
    page: 1,
  };
  total = 0;

  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  showServicesModal = null;
  showRequestedServicesModal = null;

  loading = false;

  headers = [
    {
      text: "Azienda",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Unione Nazionale",
      align: "start",
      sortable: true,
      value: "union",
    },
    {
      text: "Gruppo Di Interesse",
      align: "start",
      sortable: true,
      value: "gruppo_interesse.des",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
    { text: "", value: "data-table-expand" },
  ];
  selected = [];

  async mounted() {
    await this.fetchCompanies(true);
  }

  async fetchCompanies(resetPagination: boolean) {
    const { page, itemsPerPage } = this.options;
    if (resetPagination) {
      this.options = {
        itemsPerPage: 10,
        page: 1,
      };
    }
    this.total = await this.getCompanies({
      type: CompaniesListTypes.Complete,
      text: this.search,
      pageIndex: page,
      pageSize: itemsPerPage,
    });
  }

  handleClick(item) {
    console.log(item);
    // this.$router.push({
    //   name: RoutesNames.companiesManageDetails,
    //   params: { companyGuid: item.guid },
    // });
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailCompany(
          $guid: [String]
          $subject: String!
          $text: String!
        ) {
          sendEmailCompany(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: _.map(this.selected, "guid"),
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailCompany) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  async showHandleServicesModal(company) {
    await this.setSelectedCompanies([company]);
    const services = _.filter(company.services, {
      status: ServiceStatus.ASSIGNED,
    });
    await this.setSelectedServices(services);
    this.showServicesModal = true;
  }

  async showHandleRequestedServicesModal(company) {
    await this.setSelectedCompanies([company]);
    const services = _.filter(company.services, {
      status: ServiceStatus.REQUEST,
    });
    await this.setSelectedServices(services);
    this.showRequestedServicesModal = true;
  }

  getServices(companyService) {
    return extractServicsFromCompanyServices(companyService);
  }
}
