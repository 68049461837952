
import CompaniesServicesTable from "@/components/CompaniesServicesTable.vue";
import Card from "@/components/Card.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { CompaniesListTypes } from "@/store/modules/data";

@Component({
  components: {
    Card,
    CompaniesServicesTable,
  },
})
export default class ServicesCompaniesListView extends Vue {

}
