
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import Btn from "@/components/Btn.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import { ServiceStatus } from "@/utils/status";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { CompaniesListTypes } from "@/store/modules/data";

@Component({
  components: {
    Modal,
    Avatar,
    ServicesSelector,
    SelectedServices,
    Btn
  },
  watch: {
    selectedCompanies(val) {
      if (val.length == 1) {
        this.company = val[0];
        this.services = _.filter(this.company.services, {
          status: ServiceStatus.ASSIGNED,
        });
      }
    },
    selectedServices(val) {
      this.removed = _.differenceBy(this.services, val, "guid");
      this.allowSave = false;
    },
  },
})
export default class EditAssignedServicesOfCompanyModal extends Vue {
  @State((state: StateStore) => state.data.selectedCompanies) selectedCompanies;
  @State((state: StateStore) => state.data.selectedServices) selectedServices;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedServices;
  @Mutation setSelectedServices;
  @Mutation resetSelectedCompanies;
  @Action updateCompanyServices;
  @Action getCompanies;

  @Prop()
  value;

  loading = false;
  allowSave = false;

  company = null;
  services = [];
  removed = [];

  async save() {
    if (this.removed.length > 0) {
      const updated = await this.updateCompanyServices({
        companies: [this.company.guid],
        services: _.map(this.removed, "guid"),
        status: ServiceStatus.DELETED,
      });
      if (updated) {
        await this.getCompanies({ type: CompaniesListTypes.Complete });
        this.showSuccess("Servizi modificati correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica servizi");
      }
    }
  }

  onClose() {
    this.resetSelectedServices();
    this.resetSelectedCompanies();
    this.$emit("close");
  }
}
