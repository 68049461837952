import { CompanyService, Service } from "@/types/graphql";

import _ from "lodash";
import moment from "moment";

export function extractServicsFromCompanyServices(
  companyServices: CompanyService[]
): Service[] {
  const services: Service[] = [];

  _.forEach(companyServices, (cs) => {
    services.push(cs.service);
  });
  return services;
}

export interface Duration {
  label: string;
  value: string;
  duration: moment.Duration;
}

export const CUSTOM_EXPIRATION = "CUSTOM";

export const expirationData: Duration[] = [
  {
    label: "Scadenza personalizzata",
    value: CUSTOM_EXPIRATION,
    duration: moment.duration(0, "seconds"),
  },
  {
    label: "3 Mesi",
    value: "3_MONTHS",
    duration: moment.duration(3, "months"),
  },
  {
    label: "6 Mesi",
    value: "6_MONTHS",
    duration: moment.duration(6, "months"),
  },
  { label: "1 Anno", value: "1_YEARS", duration: moment.duration(1, "years") },
  { label: "2 Anni", value: "2_YEARS", duration: moment.duration(2, "years") },
  { label: "3 Anni", value: "3_YEARS", duration: moment.duration(3, "years") },
  { label: "4 Anni", value: "4_YEARS", duration: moment.duration(4, "years") },
  { label: "5 Anni", value: "5_YEARS", duration: moment.duration(5, "years") },
  { label: "6 Anni", value: "6_YEARS", duration: moment.duration(6, "years") },
  { label: "7 Anni", value: "7_YEARS", duration: moment.duration(7, "years") },
  { label: "8 Anni", value: "8_YEARS", duration: moment.duration(8, "years") },
  { label: "9 Anni", value: "9_YEARS", duration: moment.duration(9, "years") },
  {
    label: "10 Anni",
    value: "10_YEARS",
    duration: moment.duration(10, "years"),
  },
];

export function getDurationLabelFromValue(durationValue: string) {
  return expirationData.find((duration) => {
    return duration.value == durationValue;
  })?.label;
}
